<template>
  <div class="page">
    <webHeader :currentIndex="currentIndex"></webHeader>
    <div class="centerMain">
      <navLeft
        :current="1"
        :navList="navList"
        @exchange="exchange"
        :title="$t('webHeader.personCenter')"
      ></navLeft>
      <div class="mainPage">
        <!--消息列表-->
        <div v-if="list.length > 0">
          <van-row class="chat" v-for="item in list" :key="item.id">
            <van-row>
              <van-col span="12" class="name">
                {{ item.username }}
                <span class="dot" v-if="+item.count">
                  {{ item.count }}
                </span>
              </van-col>
              <van-col span="12" class="time">
                {{ item.lastmsg.createtime | dateformat }}
              </van-col>
            </van-row>
            <div class="van-ellipsis info">
              <span v-if="item.lastmsg.type === 'text'">
                {{ item.lastmsg.content }}
              </span>
              <van-icon name="photo-o" v-else style="font-size:1rem" />
            </div>
            <div class="van-hairline--bottom"></div>
          </van-row>
        </div>
        <!--暂无记录-->
        <van-empty v-if="list.length == 0" :description="$t('common.nomore')" />
        <audio controls ref="notify" style="display:none">
          <source src="msg.mp3" />
        </audio>
      </div>
    </div>
    <webFooter></webFooter>
  </div>
</template>

<script>
import webHeader from "@/components/web/webHeader.vue";
import navLeft from "@/components/web/navLeft.vue";
import webFooter from "@/components/web/webFooter.vue";
export default {
  components: { webHeader, navLeft, webFooter },
  data() {
    return {
      currentIndex: 5,
      navList: [
        {
          icon: require("../../../assets/img/web/iconAuth.png"),
          name: this.$t("home.gj_auth"),
          path: "/web/my/index",
        },
        {
          icon: require("../../../assets/img/web/iconMessage.png"),
          name: this.$t("home.message"),
          path: "/web/my/Contact_list",
        },
        // {
        // 	icon:require('../../../assets/img/serve_my.svg'),
        // 	name:this.$t('home.serverurl'),
        // 	path:this.serverurl,
        // },
        {
          icon: require("../../../assets/img/web/iconSetting.png"),
          name: this.$t("home.set"),
          path: "/web/my/setting",
        },
      ],
      list: [],
      websocket: null,
      userid: "",
    };
  },
  created() {
    this.isread();
    this.getinfo();
  },
  methods: {
    async isread() {
      const { data } = await this.$http.get("/home/user/isread");
    },
    async getinfo() {
      const { data } = await this.$http.get("/home/user/chatlist");
      if (data) {
        if (data.code === 200) {
          this.list = data.data.list;
          this.userid = data.data.userid;
          if (this.websocket == null) {
            this.initwebsocket();
          }
        }
      }
    },
    // 去聊天页
    tochat(orderid) {
      this.$router.push("/trade/shopping/trading/contact/" + orderid);
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + "/wss:8880");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onerror = this.websocketonerror;
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket();
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data);
      switch (message.type) {
        case "init":
          this.binduid();
          break;
        case "text":
          this.getinfo();
          this.$refs.notify.play();
          break;
        case "image":
          this.getinfo();
          this.$refs.notify.play();
          break;
      }
    },
    // 绑定uid
    binduid() {
      const msg = { type: "bind", userid: this.userid };
      this.websocketsend(JSON.stringify(msg));
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data);
    },
    exchange(data) {},
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #141416;
  min-height: 100vh;
  .centerMain {
    padding: 20px 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .mainPage {
      min-height: calc(100vh - 80px);
      background-color: #18191d;
      color: #fff;
      width: 1000px;
      text-align: left;
    }
  }
}
.verification {
  .identity {
    width: 90%;
    height: 13.33333rem;
    margin-left: 5%;
    margin-top: 4%;
    border-radius: 0.53333rem;
    .van-cell {
      font-size: 1rem;
      color: #000 !important;
      border-radius: 0.53333rem;
      .van-cell__title {
        text-align: left;
      }
      .van-cell:after {
        border-bottom: 0.02667rem solid #e5e5e5;
      }
    }
    .identity-notice span {
      color: #b2b2b2;
      display: block;
      font-size: 0.98667rem;
      text-align: left;
      margin: 1.33333rem 0 0 1.33333rem;
    }
  }
}
.verificationForm {
  padding: 20px;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    .nav {
      cursor: pointer;
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid #fff;
      border-radius: 5px;
      margin-right: 10px;
    }
    .titText {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .spacing {
    margin-top: 1rem;
    .field {
      background-color: #f1f1f2;
      margin-top: 0.53333rem;
      width: 90%;
      margin-left: 5%;
      border-radius: 0.53333rem;
      span {
        color: #000;
        font-size: 1.86667rem;
      }
      .van-cell {
        font-size: 0.98667rem;
        background-color: #f1f1f2 !important;
        color: #fff !important;
      }
      .van-field__control {
        padding-right: 1.2rem;
      }
      /deep/.van-field input {
        color: #000;
        text-align: left;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .submitBtn {
      margin-top: 10px;
      width: 100%;
      letter-spacing: 0.53333rem;
      height: 4rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
  }
  .uploading-image {
    height: 10rem;
    width: 80%;
    margin: 2% 10% 5% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02667rem dashed grey;
    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
      /deep/.van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #fff;
        color: #fff;
      }
    }
  }
}
</style>
